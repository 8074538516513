// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pHA6Ygpgl"];

const variantClassNames = {pHA6Ygpgl: "framer-v-4as0ft"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "pHA6Ygpgl", title: VmBNYKmmQ = "Button", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pHA6Ygpgl", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jyMdG", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-4as0ft", className)} data-border data-framer-name={"button"} layoutDependency={layoutDependency} layoutId={"pHA6Ygpgl"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(0, 0, 0)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} transition={transition}><Text __fromCanvasComponent alignment={"left"} className={"framer-1yafudq"} data-framer-name={"text"} fonts={["GF;Inter-regular"]} layoutDependency={layoutDependency} layoutId={"xDEPKhG0W"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Button</span><br></span></span>"} style={{"--framer-font-family": "\"Inter\", sans-serif", "--framer-font-size": "16px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0px", "--framer-line-height": "150%", "--framer-text-alignment": "left", "--framer-text-color": "rgb(255, 255, 255)", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={VmBNYKmmQ} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jyMdG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jyMdG * { box-sizing: border-box; }", ".framer-jyMdG .framer-x50f9w { display: block; }", ".framer-jyMdG .framer-4as0ft { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 12px 24px 12px 24px; position: relative; width: min-content; }", ".framer-jyMdG .framer-1yafudq { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jyMdG .framer-4as0ft { gap: 0px; } .framer-jyMdG .framer-4as0ft > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-jyMdG .framer-4as0ft > :first-child { margin-left: 0px; } .framer-jyMdG .framer-4as0ft > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 98
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"VmBNYKmmQ":"title"}
 */
const Frameru4ExFhIna: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Frameru4ExFhIna;

Frameru4ExFhIna.displayName = "button";

Frameru4ExFhIna.defaultProps = {height: 48, width: 98};

addPropertyControls(Frameru4ExFhIna, {VmBNYKmmQ: {defaultValue: "Button", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Frameru4ExFhIna, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/u4ExFhIna:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf", weight: "400"}])